import { ErrorHandler } from "@common";
import {
  AddEditGame,
  AvailableGames,
  Games,
  Login,
  Logout,
  MyGames,
  SignUp,
} from "@routes";
import { api } from "@services";
import { Game } from "@types";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { GameDetails } from "routes/games/GameDetails";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import "./i18n";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" Component={App} ErrorBoundary={ErrorHandler}>
      <Route path="games" Component={Games} hasErrorBoundary>
        <Route path="" Component={AvailableGames} />
        <Route
          path="create"
          Component={AddEditGame}
          loader={async () => {
            const disciplinesRequest = await api.get("disciplines/list");
            const placesRequest = await api.get("places/list");

            return {
              places: placesRequest.data,
              disciplines: disciplinesRequest.data,
            };
          }}
        />
        <Route path="my" Component={MyGames} />
        <Route path=":gameId">
          <Route
            path=""
            Component={GameDetails}
            loader={async ({ params }) => {
              const gameRequest = await api.get<Game>(`games/${params.gameId}`);
              return gameRequest.data;
            }}
          />
          <Route
            path="edit"
            Component={AddEditGame}
            loader={async ({ params }) => {
              const disciplinesRequest = await api.get("disciplines/list");
              const placesRequest = await api.get("places/list");
              const gameRequest = await api.get<Game>(`games/${params.gameId}`);

              return {
                places: placesRequest.data,
                disciplines: disciplinesRequest.data,
                game: gameRequest.data,
              };
            }}
          />
        </Route>
      </Route>
      <Route path="login" Component={Login} />
      <Route path="sign-up" Component={SignUp} />
      <Route path="logout" Component={Logout} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

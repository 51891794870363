import { Home16Filled } from "@fluentui/react-icons";
import { Link, useRouteError } from "react-router-dom";

export type ErrorResponse = {
  error: Error;
  data: string;
  internal: boolean;
  status: number;
  statusText: string;
};

export const ErrorHandler = () => {
  const error = useRouteError() as ErrorResponse;

  return (
    <div className="p-4 bg-brand text-white h-full flex flex-col items-center justify-center">
      <h1 className="text-9xl">{error.status}</h1>

      <p>{error.statusText}</p>

      <Link to="/" className="flex flex-row gap-1 items-center">
        <Home16Filled /> Home
      </Link>
    </div>
  );
};

import {
  Avatar,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Switch,
  SwitchOnChangeData,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  NavigationRegular,
  PersonFilled,
  WeatherMoon16Regular,
  WeatherSunny16Regular,
} from "@fluentui/react-icons";
import useAuth from "hooks/useAuth";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocalStorage, useMediaQuery } from "usehooks-ts";

const useStyles = makeStyles({
  header: {
    backgroundColor: tokens.colorBrandBackgroundStatic,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  link: {
    color: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.transition("color", tokens.durationSlower),
    ":hover": {
      color: tokens.colorNeutralForeground2LinkHover,
    },
    ":active": {
      color: tokens.colorNeutralForeground2LinkPressed,
    },
    ":focus": {
      color: tokens.colorNeutralForeground2LinkSelected,
    },
  },
});

type HeaderProps = {
  themeChanged?: (
    ev: ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => void;
};

type NavigationItem = {
  path: string;
  labelKey: string;
};

const NavigationItems: NavigationItem[] = [
  {
    labelKey: "header.games",
    path: "/games",
  },
  // {
  //   labelKey: "header.groups",
  //   path: "/groups",
  // },
  // {
  //   labelKey: "header.places",
  //   path: "/plaes",
  // },
];

export const Header = ({ themeChanged }: HeaderProps) => {
  const classes = useStyles();

  const [theme] = useLocalStorage("theme", "light");
  const [checked, setChecked] = useState(theme === "dark");
  const { isLoggedIn, username } = useAuth();
  const navigate = useNavigate();

  const matchesMobile = useMediaQuery("(max-width: 767px)");

  const handleThemeChange = (
    ev: ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => {
    setChecked(data.checked);
    themeChanged!(ev, data);
  };

  const { t } = useTranslation();

  return (
    <header className={classes.header}>
      <div id="header-wrapper" className="p-4 flex flex-row gap-4 items-center">
        {matchesMobile && (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton
                appearance="transparent"
                icon={<NavigationRegular fontSize={16} />}
              />
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {NavigationItems.map((item, index) => (
                  <MenuItem key={index} onClick={() => navigate(item.path)}>
                    {t(item.labelKey)}
                  </MenuItem>
                ))}
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
        <RouterLink to="/">
          <h1 className="text-xl">{t("header.title")}</h1>
        </RouterLink>
        {!matchesMobile &&
          NavigationItems.map((item, index) => (
            <RouterLink key={index} to={item.path}>
              <Link className={classes.link}>{t(item.labelKey)}</Link>
            </RouterLink>
          ))}
        <span className="grow-1 w-full"></span>
        <div className="flex items-center">
          <WeatherSunny16Regular />
          <Switch checked={checked} onChange={handleThemeChange} />
          <WeatherMoon16Regular />
        </div>
        <div className="flex items-center gap-4">
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              {isLoggedIn ? (
                <Avatar
                  color="colorful"
                  idForColor={`${username}`}
                  className="cursor-pointer"
                  name={username}
                />
              ) : (
                <MenuButton
                  appearance="transparent"
                  icon={<PersonFilled fontSize={16} />}
                />
              )}
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                {isLoggedIn
                  ? [
                      <MenuItem
                        key="logout"
                        onClick={() => navigate("/logout")}
                      >
                        {t("header.logout")}
                      </MenuItem>,
                    ]
                  : [
                      <MenuItem key="login" onClick={() => navigate("/login")}>
                        {t("header.login")}
                      </MenuItem>,
                      <MenuItem
                        key="signup"
                        onClick={() => navigate("/sign-up")}
                      >
                        {t("header.register")}
                      </MenuItem>,
                    ]}
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;

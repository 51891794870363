import { useLocalStorage } from "usehooks-ts";

import { DecodedToken } from "@types";
import { decodeToken, isExpired } from "react-jwt";

interface UseAuthOutput {
  isLoggedIn: boolean;
  username: string | undefined;
  expired: boolean;
  userId: number | undefined;
}

function useAuth(): UseAuthOutput {
  const [token] = useLocalStorage<string | null>("token", "");

  const isLoggedIn = !!token?.length;
  const decodedToken = token ? decodeToken<DecodedToken>(token) : null;
  const username = decodedToken?.username;
  const expired = !!token && isExpired(token);
  const userId = decodedToken?.id;

  return { isLoggedIn, username, expired, userId };
}

export default useAuth;

import {
  Button,
  Card,
  Field,
  Input,
  Link,
  Title2,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { api } from "@services";
import { AxiosError, AxiosResponse } from "axios";
import { KeyboardEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

type FormData = {
  username: string;
  password: string;
};

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    ...shorthands.padding("2rem"),
    backgroundColor: tokens.colorNeutralBackground2,
    width: "400px",
    maxWidth: "100%",
  },
  title: {
    width: "100%",
    display: "block",
    textAlign: "center",
  },
});

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const classes = useStyles();

  const navigate = useNavigate();

  const [pendingRequest, setPendingRequest] = useState(false);
  const [token, setToken] = useLocalStorage<string>("token", "");

  const { t } = useTranslation();

  const login = async (data: FormData) => {
    console.log(pendingRequest, token);
    try {
      setPendingRequest(true);

      const response: AxiosResponse = await api.post("auth/login", data);
      setToken(response.data.token);

      navigate("/");
    } catch (error) {
      console.log(error as AxiosError);
    } finally {
      setPendingRequest(false);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") handleSubmit(login)();
    return;
  };

  return (
    <Card className={classes.card} appearance="outline">
      <header>
        <Title2 as="h2" className={classes.title}>
          {t("login.title")}
        </Title2>
      </header>

      <form
        onSubmit={handleSubmit(login)}
        className="flex flex-col gap-4 justify-stretch"
      >
        <Field
          label={t("login.emailOrUsername")}
          required
          validationMessage={errors.username?.message}
        >
          <Input
            type="email"
            {...register("username", {
              required: t("errors.required"),
            })}
            onKeyDown={handleKeyDown}
          />
        </Field>
        <Field
          label={t("login.password")}
          required
          validationMessage={errors.password?.message}
        >
          <Input
            type="password"
            {...register("password", {
              required: t("errors.required"),
            })}
            onKeyDown={handleKeyDown}
          />
        </Field>
        <RouterLink className="place-self-end" to="/reset-password">
          <Link>{t("login.forgotPassword")}</Link>
        </RouterLink>
        <Button appearance="primary" onClick={handleSubmit(login)}>
          {t("login.login")}
        </Button>
        <span className="place-self-center text-xs">
          {t("login.noAccount")}
        </span>
        <RouterLink className="place-self-center" to="/sign-up">
          <Link>{t("login.register")}</Link>
        </RouterLink>
      </form>
    </Card>
  );
};

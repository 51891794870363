import images from "@assets";
import {
  AvatarGroup,
  AvatarGroupItem,
  AvatarGroupPopover,
  Badge,
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  Image,
  Link,
  Spinner,
  Title3,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  Tooltip,
  makeStyles,
  partitionAvatarGroupItems,
  shorthands,
  tokens,
  useToastController,
} from "@fluentui/react-components";
import {
  CalendarLtrRegular,
  CityRegular,
  ClockRegular,
  LocationRegular,
  MoneyRegular,
  OpenRegular,
  PeopleCommunityRegular,
  PersonAddRegular,
  PersonDeleteRegular,
  TimerRegular,
} from "@fluentui/react-icons";
import { api } from "@services";
import { Game } from "@types";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { JoinDialog } from "./JoinDialog";

type GamemOverviewProps = {
  game: Game;
  gameChanged: (game: Game) => void;
};

const useStyles = makeStyles({
  card: {
    minWidth: "200px",
    maxWidth: "720px",
  },
  leaveButton: {
    "& svg": {
      color: tokens.colorPaletteRedForeground2,
      "&:hover": {
        color: tokens.colorPaletteRedForeground3,
      },
    },
  },
  headerWrapper: {
    ...shorthands.gap("0", "8px"),

    "& .fui-CardHeader__header": {
      ...shorthands.gap("4px"),
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  previewLine: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    ...shorthands.gap("4px"),
    "&  > span[hidden]": {
      display: "none",
    },
  },
  image: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
  },
  preview: {
    ...shorthands.padding("16px"),
  },
  previewSectionWrapper: {
    position: "relative",
    zIndex: 10,
    backgroundColor: tokens.colorBackgroundOverlay,
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },
  previewSection: {
    display: "flex",
    flexDirection: "column",
    color: tokens.colorNeutralForegroundStaticInverted,
    ...shorthands.padding("8px"),
    ...shorthands.gap("4px"),
    "& span": {
      display: "flex",
      alignItems: "center",
    },
  },
  horizontalCardImage: {
    width: "64px",
    height: "64px",
  },
});

export const GameOverview = ({ game, gameChanged }: GamemOverviewProps) => {
  const styles = useStyles();
  const { userId } = useAuth();
  type FormData = {
    playername?: string;
    comment: string;
    priorityCode: string;
    securityCode?: string;
  };

  const navigate = useNavigate();

  const { dispatchToast } = useToastController("mainToaster");
  const [joinDialogOpen, setJoinDialogOpen] = useState(false);
  const [pendingRequest, setPendingRequest] = useState(false);

  const handleUserJoin = async ({
    playername,
    comment,
    priorityCode,
    securityCode,
  }: FormData) => {
    setPendingRequest(true);
    const result = await api.post(
      `games/${game.id}/${!!playername ? "guest-" : ""}join${
        priorityCode.length ? `?code=${priorityCode}` : ""
      }`,
      { playername, comment, securityCode }
    );
    gameChanged(result.data);

    setJoinDialogOpen(false);

    dispatchToast(
      <Toast>
        <ToastTitle>{t("games.overview.toast.joinTitle")}</ToastTitle>
        <ToastBody>{t("games.overview.toast.joinBody")}</ToastBody>
        <ToastFooter>
          <ToastTrigger>
            <NavLink to={`/games/${game.id}`}>
              <Link>{t("games.overview.toast.showGame")}</Link>
            </NavLink>
          </ToastTrigger>
        </ToastFooter>
      </Toast>,
      { intent: "info" }
    );
    setPendingRequest(false);
  };

  const handleLeaveUser = async () => {
    setPendingRequest(true);
    const result = await api.post(`games/${game.id}/leave`);
    gameChanged(result.data);

    dispatchToast(
      <Toast>
        <ToastTitle>{t("games.overview.toast.leaveTitle")}</ToastTitle>
        <ToastBody>{t("games.overview.toast.leaveBody")}</ToastBody>
        <ToastFooter>
          <ToastTrigger>
            <NavLink to={`/games/${game.id}`}>
              <Link>{t("games.overview.toast.showGame")}</Link>
            </NavLink>
          </ToastTrigger>
        </ToastFooter>
      </Toast>,
      { intent: "info" }
    );
    setPendingRequest(false);
  };

  const isPlayer = !!game.players.find((player) => player.userId === userId);

  const { t } = useTranslation();

  const partitionedItems = partitionAvatarGroupItems({
    items: game.players.map((player) => ({
      id: player.id,
      username: player.user?.username || player.playername,
    })),
  });

  return (
    <>
      <Card appearance="outline" className={styles.card}>
        <CardHeader
          className={styles.headerWrapper}
          header={
            <>
              <Title3>
                <span>{game.place.name}</span>
              </Title3>
              <span className="flex items-center gap-1">
                <PeopleCommunityRegular fontSize={20} />
                <AvatarGroup size={24} layout="stack">
                  {partitionedItems.inlineItems.map((player) => (
                    <AvatarGroupItem name={player.username} key={player.id} />
                  ))}
                  {partitionedItems.overflowItems && (
                    <AvatarGroupPopover
                      tooltip={{
                        content: t("games.overview.more"),
                        relationship: "label",
                      }}
                    >
                      {partitionedItems.overflowItems.map((player) => (
                        <AvatarGroupItem
                          name={player.username}
                          key={player.id}
                        />
                      ))}
                    </AvatarGroupPopover>
                  )}
                </AvatarGroup>
              </span>
            </>
          }
        />

        <CardPreview className={styles.preview}>
          <Image
            className={styles.image}
            src={images[game.discipline.iconName]}
            fit="cover"
          />
          <section className={styles.previewSectionWrapper}>
            <section className={styles.previewSection}>
              <Body1 className={styles.previewLine}>
                <Tooltip
                  withArrow
                  content={t("games.overview.place")}
                  relationship="label"
                >
                  <span>
                    <Badge appearance="tint" color="informative">
                      <CityRegular fontSize={16} />
                      {game.place.city}
                    </Badge>
                  </span>
                </Tooltip>
                <Tooltip
                  withArrow
                  content={t("games.overview.address")}
                  relationship="label"
                >
                  <span>
                    <Badge appearance="tint" color="informative">
                      <LocationRegular fontSize={16} />
                      {game.place.street} {game.place.number}
                    </Badge>
                  </span>
                </Tooltip>
              </Body1>
              <Body1 className={styles.previewLine}>
                <Tooltip
                  withArrow
                  content={t("games.overview.date")}
                  relationship="label"
                >
                  <span>
                    <Badge appearance="tint" color="subtle">
                      <CalendarLtrRegular fontSize={16} />
                      {new Date(game.date).toLocaleDateString()}
                    </Badge>
                  </span>
                </Tooltip>

                <Tooltip
                  withArrow
                  content={t("games.overview.timeStart")}
                  relationship="label"
                >
                  <span>
                    <Badge appearance="tint" color="subtle">
                      <ClockRegular fontSize={16} />
                      {new Date(game.date).toLocaleTimeString()}
                    </Badge>
                  </span>
                </Tooltip>
                {game.duration && (
                  <Tooltip
                    withArrow
                    content={t("games.overview.duration")}
                    relationship="label"
                  >
                    <span>
                      <Badge appearance="tint" color="subtle">
                        <TimerRegular fontSize={16} />
                        {game.duration}
                      </Badge>
                    </span>
                  </Tooltip>
                )}
              </Body1>
              <Body1 className={styles.previewLine}>
                <Tooltip
                  withArrow
                  content={t("games.overview.cost")}
                  relationship="label"
                >
                  <span>
                    <Badge appearance="tint" color="subtle">
                      <MoneyRegular fontSize={16} />
                      {game.cost}
                    </Badge>
                  </span>
                </Tooltip>
                <Tooltip
                  withArrow
                  content={t("games.overview.playerCount")}
                  relationship="label"
                >
                  <span>
                    <Badge
                      appearance="tint"
                      color={
                        game.players.length > game.minPlayers
                          ? game.players.length > game.maxPlayers
                            ? "danger"
                            : "success"
                          : "important"
                      }
                    >
                      <PeopleCommunityRegular fontSize={16} />
                      {game.players.length}/{game.maxPlayers}
                    </Badge>
                  </span>
                </Tooltip>
              </Body1>
            </section>
          </section>
        </CardPreview>

        <CardFooter>
          {!isPlayer ? (
            <Button
              appearance="primary"
              disabled={pendingRequest}
              onClick={() => setJoinDialogOpen(true)}
              icon={<PersonAddRegular fontSize={16} />}
            >
              {t("games.overview.join")}
            </Button>
          ) : (
            <Button
              appearance="subtle"
              disabled={pendingRequest}
              className={styles.leaveButton}
              onClick={handleLeaveUser}
              icon={
                pendingRequest ? (
                  <Spinner size="tiny" />
                ) : (
                  <PersonDeleteRegular fontSize={16} />
                )
              }
            >
              {t("games.overview.leave")}
            </Button>
          )}
          <Button
            appearance="subtle"
            onClick={() => {
              navigate(`/games/${game.id}`);
            }}
            icon={<OpenRegular fontSize={16} />}
          >
            {t("games.overview.details")}
          </Button>
        </CardFooter>
      </Card>
      <JoinDialog
        game={game}
        onOpenChange={setJoinDialogOpen}
        open={joinDialogOpen}
        pendingRequest={pendingRequest}
        submitHandler={handleUserJoin}
      />
    </>
  );
};

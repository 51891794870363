import {
  SelectTabData,
  SelectTabEvent,
  Slot,
  Tab,
  TabList,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";

const useStyles = makeStyles({
  nav: {
    backgroundColor: tokens.colorNeutralBackground2,
    color: tokens.colorNeutralForegroundOnBrand,
    overflowX: "auto",
    flexBasis: "auto",
    flexShrink: 0,
  },
});

export type SideNavItem = {
  icon?: Slot<"span">;
  value: string;
  labelKey: string;
};

export type SideNavProps = {
  items: SideNavItem[];
  defaultSelectedValue: string;
  tabChange?: (event: SelectTabEvent, data: SelectTabData) => void;
};

export const SideNav: React.FC<SideNavProps> = ({
  items,
  defaultSelectedValue,
  tabChange,
}) => {
  const classes = useStyles();
  const matchesMobile = useMediaQuery("(max-width: 767px)");
  const { t } = useTranslation();
  return (
    <section className={classes.nav}>
      <nav>
        <TabList
          defaultSelectedValue={defaultSelectedValue}
          vertical={!matchesMobile}
          onTabSelect={tabChange}
          size="large"
        >
          {items.map((item) => (
            <Tab key={item.value} icon={item.icon} value={item.value}>
              {t(item.labelKey)}
            </Tab>
          ))}
        </TabList>
      </nav>
    </section>
  );
};

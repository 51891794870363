import { DecodedToken } from "@types";
import { decodeToken, isExpired } from "react-jwt";
import { useLocalStorage } from "usehooks-ts";
export const Home = () => {
  const [token] = useLocalStorage<string>("token", "");
  return (
    <>
      {token && (
        <div>
          <p>{isExpired(token) ? "Expired" : "Valid"}</p>
          {Object.entries(decodeToken<DecodedToken>(token)!).map((entry) => {
            return (
              <p key={entry[0]}>
                {entry[0]}: {entry[1]}
              </p>
            );
          })}
        </div>
      )}
    </>
  );
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export const Logout = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useLocalStorage<string | null>("token", null);
  const navigate = useNavigate();
  useEffect(() => {
    setToken(null);
    localStorage.removeItem("token");
    navigate("/");
  });
  return null;
};

import images from "@assets";
import {
  Body1,
  Body2,
  Divider,
  Image,
  Title3,
  Toast,
  ToastBody,
  ToastTitle,
  makeStyles,
  shorthands,
  tokens,
  useToastController,
} from "@fluentui/react-components";
import { api } from "@services";
import { GameDetails as Game } from "@types";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { JoinDialog } from "./JoinDialog";
import { PlayersTable } from "./PlayersTable";
import { GameDetailsToolbar } from "./toolbar/GameDetailsToolbar";

const useStyles = makeStyles({
  divider: {
    ...shorthands.margin("16px", "0"),
  },
  verticalDivider: {
    ...shorthands.margin("0", "16px"),
    ...shorthands.flex(0, 1, "auto"),
  },
  image: {
    top: 0,
    left: "0",
    bottom: 0,
    right: "0",
    position: "absolute",
  },
  headerWrapper: {
    display: "inline-flex",
    position: "relative",
    zIndex: 10,
    backgroundColor: tokens.colorBackgroundOverlay,
    color: tokens.colorNeutralForegroundStaticInverted,
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },
  header: {
    backgroundColor: tokens.colorBackgroundOverlay,
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.padding("8px"),
  },
});

type FormData = {
  playername?: string;
  comment: string;
  priorityCode: string;
  securityCode?: string;
};

export const GameDetails = () => {
  const { t } = useTranslation();
  const loaderData = useLoaderData() as Game;
  const [game, setGame] = useState<Game | undefined>(undefined);

  useEffect(() => {
    setGame(loaderData);
  }, [loaderData]);

  const { userId } = useAuth();
  const styles = useStyles();
  // const [selectedRows, setSelectedRows] = useState<Set<TableRowId>>(new Set());
  const [isJoining, setIsJoining] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const [joinDialogOpen, setJoinDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { dispatchToast } = useToastController("mainToaster");

  const handleUserJoin = useCallback(
    async ({ playername, securityCode, comment, priorityCode }: FormData) => {
      setIsJoining(true);
      const result = await api.post(
        `games/${game?.id}/join${
          priorityCode.length ? `?code=${priorityCode}` : ""
        }`,
        { comment, playername, securityCode }
      );

      setGame(result.data);

      setJoinDialogOpen(false);

      dispatchToast(
        <Toast>
          <ToastTitle>{t("games.overview.toast.joinTitle")}</ToastTitle>
          <ToastBody>{t("games.overview.toast.joinBody")}</ToastBody>
        </Toast>,
        { intent: "info" }
      );
      setIsJoining(false);
    },
    [dispatchToast, t, game]
  );

  const handleLeaveUser = async () => {
    setIsLeaving(true);
    const result = await api.post(`games/${game?.id}/leave`);
    setGame(result.data);

    dispatchToast(
      <Toast>
        <ToastTitle>{t("games.overview.toast.leaveTitle")}</ToastTitle>
        <ToastBody>{t("games.overview.toast.leaveBody")}</ToastBody>
      </Toast>,
      { intent: "info" }
    );
    setIsLeaving(false);
  };

  const isGameManager = !!game?.managers.find(
    (manager) => manager.userId === userId
  );

  const isGamePlayer = !!game?.players.find(
    (player) => player.userId === userId
  );

  const onPaidChange = async (ids: number[], paid: boolean) => {
    const result = await api.put(`games/${game?.id}/set-payment`, {
      players: ids,
      paid,
    });

    setGame(result.data);
  };

  return (
    <>
      {game && (
        <>
          <GameDetailsToolbar
            isManager={isGameManager}
            isPlayer={isGamePlayer}
            pendingLeaveRequest={isLeaving}
            onJoin={() => setJoinDialogOpen(true)}
            onLeave={handleLeaveUser}
            onDelete={() => console.log("delete")}
            onEdit={() => navigate("edit")}
          />
          <section className="relative p-4 mt-2">
            <Image
              className={styles.image}
              src={images[game.discipline.iconName]}
              fit="cover"
            />
            <span className={styles.headerWrapper}>
              <Title3 className={styles.header}>
                {game.title} @{game.place.name} |{" "}
                {moment(game.date).format("yyyy-MM-DD HH:mm")}{" "}
                {game.duration && "- " + game.duration}
              </Title3>
            </span>
          </section>
          <section className="p-4 grow basis-full flex flex-col gap-4">
            <section className="flex">
              <section className="w-full sm:w-1/4">
                <Divider alignContent="start" appearance="subtle" inset>
                  <Body2>{t("games.details.address")}</Body2>
                </Divider>
                <Body1>
                  {game.place.street} {game.place.number}
                </Body1>
                <br />
                <Body1>{game.place.city}</Body1>
              </section>
              <Divider vertical className={styles.verticalDivider} />
              <section className="flex-grow">
                <Divider alignContent="start" appearance="subtle" inset>
                  <Body2>{t("games.details.drive")}</Body2>
                </Divider>
                <iframe
                  title={t("games.details.drive")}
                  className="w-full border-none"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={
                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyCgZ-EuWp_YcsWuLw8A4FZ5STftbMG2p_Q&q=" +
                    encodeURIComponent(
                      `${game.place.street} ${game.place.number}, ${game.place.city}`
                    )
                  }
                ></iframe>
              </section>
            </section>

            <Divider alignContent="start" appearance="subtle" inset>
              <Body2>{t("games.details.details")}</Body2>
            </Divider>
            <div className="flex flex-col gap-4">
              <Body1>
                {t(`games.details.${game.private ? "private" : "public"}`)}
              </Body1>
              {game.description && <Body1>{game.description}</Body1>}
              <Body1>
                {t("games.details.createdAt", {
                  createdAt: moment(game.createdAt).format("yyyy-MM-DD HH:mm"),
                })}
              </Body1>
              <Body1>
                {t("games.details.updatedAt", {
                  updatedAt: moment(game.updatedAt).format("yyyy-MM-DD HH:mm"),
                })}
              </Body1>
              {game.deletedAt && (
                <Body1>
                  {t("games.details.deletedAt", {
                    deletedAt: moment(game.deletedAt).format(
                      "yyyy-MM-DD HH:mm"
                    ),
                  })}
                </Body1>
              )}
              <Body1>
                {t("games.details.cost", {
                  cost: game.cost,
                })}
              </Body1>
              <Body1>
                {t("games.details.playersCount", {
                  minPlayers: game.minPlayers,
                  maxPlayers: game.maxPlayers,
                })}
              </Body1>
            </div>
            <Divider alignContent="start" appearance="subtle" inset>
              <Body2>{t("games.details.registeredPlayers")}</Body2>
            </Divider>
            <PlayersTable
              game={game}
              isManager={isGameManager}
              paidChange={onPaidChange}
              removePlayer={(id) => console.log(id)}
            />
          </section>
          <JoinDialog
            game={game}
            onOpenChange={setJoinDialogOpen}
            open={joinDialogOpen}
            pendingRequest={isJoining}
            submitHandler={handleUserJoin}
          />
        </>
      )}
    </>
  );
};

import {
  FluentProvider,
  SwitchOnChangeData,
  Toaster,
  makeStyles,
  mergeClasses,
  shorthands,
  teamsDarkTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import { ChangeEvent } from "react";
import { useNavigate, useOutlet } from "react-router-dom";

import { Header } from "@layout";
import { Home } from "routes/home/Home";
import { useLocalStorage, useMediaQuery } from "usehooks-ts";

import "react-calendar/dist/Calendar.css";
import { isExpired } from "react-jwt";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexGrow: 1,
  },
  "main-mobile": {
    flexDirection: "column",
  },
  "main-large": {
    flexDirection: "row",
  },
  scrollbar: {
    "& *": {
      scrollbarWidth: "thin",
      scrollbarColor: tokens.colorScrollbarOverlay,
    },
    "& *::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "& *::-webkit-scrollbar-track": {
      ...shorthands.borderRadius("8px"),
      backgroundColor: tokens.colorNeutralBackgroundStatic,
    },
    "& *::-webkit-scrollbar-track:hover": {
      backgroundColor: tokens.colorNeutralBackgroundStatic,
    },
    "& *::-webkit-scrollbar-track:active": {
      backgroundColor: tokens.colorNeutralBackgroundStatic,
    },
    "& *::-webkit-scrollbar-thumb": {
      ...shorthands.borderRadius("8px"),
      backgroundColor: tokens.colorNeutralBackground2,
    },
    "& *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: tokens.colorNeutralBackground2Hover,
    },
    "& *::-webkit-scrollbar-thumb:active": {
      backgroundColor: tokens.colorNeutralBackground2Pressed,
    },
  },
  provider: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),

    "& .react-calendar": {
      ...shorthands.border("0"),
      backgroundColor: tokens.colorSubtleBackground,
      fontFamily: tokens.fontFamilyBase,
      "& button": {
        "&:hover": {
          backgroundColor: tokens.colorSubtleBackgroundHover,
        },
        "&:focus": {
          backgroundColor: tokens.colorSubtleBackgroundPressed,
        },
        "&:active": {
          backgroundColor: tokens.colorSubtleBackgroundSelected,
        },
        "&[disabled]": {
          backgroundColor: tokens.colorNeutralBackgroundDisabled,
        },
        "&.react-calendar__month-view__days__day--weekend": {
          color: tokens.colorPaletteRedForeground1,
        },
        "&.react-calendar__tile": {
          "&--now": {
            backgroundColor: tokens.colorPaletteYellowBackground2,
            color: tokens.colorPaletteYellowForeground2,
          },
          "&--range": {
            backgroundColor: tokens.colorBrandBackground2,
          },
          "&--acitive, &--rangeStart, &--rangeEnd": {
            color: tokens.colorNeutralForegroundStaticInverted,
            backgroundColor: tokens.colorBrandBackground,
          },
        },
      },
    },
  },
});

function App() {
  const styles = useStyles();
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const [token] = useLocalStorage("token", "");
  const navigate = useNavigate();
  const outlet = useOutlet();
  const matchesMobile = useMediaQuery("(max-width: 767px)");

  if (token && isExpired(token)) {
    navigate("/logout");
  }

  const handleThemeChange: (
    ev: ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => void = (ev, { checked: isDark }) => {
    setTheme(isDark ? "dark" : "light");
  };

  return (
    <FluentProvider
      className={mergeClasses(styles.provider, styles.scrollbar)}
      theme={theme === "dark" ? teamsDarkTheme : teamsLightTheme}
    >
      <Header themeChanged={handleThemeChange} />

      <main
        className={mergeClasses(
          styles.main,
          matchesMobile ? styles["main-mobile"] : styles["main-large"]
        )}
      >
        {outlet || <Home />}
      </main>
      <Toaster toasterId="mainToaster" />
    </FluentProvider>
  );
}

export default App;

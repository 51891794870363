import {
  Toolbar,
  ToolbarButton,
  ToolbarGroup,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  AddRegular,
  ArrowSortRegular,
  FilterRegular,
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  wrapper: {
    ...shorthands.gap("4px"),
    ...shorthands.padding("8px"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground2,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  toolbar: {
    justifyContent: "space-between",
    width: "100%",
  },
});

export const GamesToolbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Toolbar className={classes.toolbar}>
        <ToolbarGroup role="presentation">
          <Tooltip
            content={t("games.gamesToolbar.filters")}
            relationship="label"
          >
            <ToolbarButton
              aria-label={t("games.gamesToolbar.filters")}
              appearance="subtle"
              icon={<FilterRegular />}
            />
          </Tooltip>

          <Tooltip content={t("games.gamesToolbar.sort")} relationship="label">
            <ToolbarButton
              aria-label={t("games.gamesToolbar.sort")}
              appearance="subtle"
              icon={<ArrowSortRegular />}
            />
          </Tooltip>
        </ToolbarGroup>
        <ToolbarGroup role="presentation">
          <Tooltip
            content={t("games.gamesToolbar.create")}
            relationship="label"
          >
            <ToolbarButton
              onClick={() => navigate("/games/create")}
              aria-label={t("games.gamesToolbar.create")}
              appearance="primary"
              icon={<AddRegular />}
            />
          </Tooltip>
        </ToolbarGroup>
      </Toolbar>
    </div>
  );
};

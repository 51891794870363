import axios from "axios";

export const api = axios.create({
  method: "get",
  baseURL: "https://dev.api.gamesync.pl",
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token.slice(1, -1)}`;
  }
  return config;
});

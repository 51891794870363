import { api } from "@services";
import { cloneDeep } from "lodash";

import { Loader } from "@common";
import { Title3 } from "@fluentui/react-components";
import { CountedResults, Game } from "@types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { GameOverview } from "./GameOverview";
import { GamesToolbar } from "./toolbar/GamesToolbar";

export const AvailableGames = () => {
  const [fetchingGames, setFetchingGames] = useState(false);
  const [games, setGames] = useState<Game[]>([]);
  const [count, setCount] = useState<number>(0);

  const getAllGames = async () => {
    const response = await api.get<CountedResults<Game>>("games");
    setGames(response.data.rows);
    setCount(response.data.count);
    setFetchingGames(false);
  };

  useEffect(() => {
    setFetchingGames(true);
    getAllGames();
  }, []);

  const { t } = useTranslation();

  const handleGameChange = (game: Game) => {
    const changedGameIndex = games.findIndex((g) => g.id === game.id);
    const updatedGames = cloneDeep(games);
    updatedGames[changedGameIndex] = game;

    setGames(updatedGames);
  };

  return (
    <>
      {fetchingGames ? (
        <Loader />
      ) : (
        <>
          <GamesToolbar />
          <section className="p-4 h-full flex flex-col gap-4">
            <Title3>
              {t("games.available.title", {
                count,
              })}
            </Title3>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}
            >
              <Masonry gutter="16px">
                {games.map((game) => (
                  <GameOverview
                    key={game.id}
                    game={game}
                    gameChanged={handleGameChange}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </section>
        </>
      )}
    </>
  );
};

import { GamesToolbar } from "./toolbar/GamesToolbar";

export const MyGames = () => {
  return (
    <>
      <GamesToolbar />
      <p>My games </p>
    </>
  );
};

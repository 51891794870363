import {
  Spinner,
  Toolbar,
  ToolbarButton,
  ToolbarGroup,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  DeleteRegular,
  EditRegular,
  PersonAddRegular,
  PersonProhibitedRegular,
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  wrapper: {
    ...shorthands.gap("4px"),
    ...shorthands.padding("8px"),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: tokens.colorNeutralBackground2,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  toolbar: {
    justifyContent: "space-between",
    width: "100%",
  },
});

type GameDetailsToolbarProps = {
  isPlayer: boolean;
  isManager: boolean;
  pendingLeaveRequest: boolean;
  onJoin: () => void;
  onLeave: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

export const GameDetailsToolbar = ({
  isManager,
  isPlayer,
  pendingLeaveRequest,
  onJoin,
  onLeave,
  onDelete,
  onEdit,
}: GameDetailsToolbarProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Toolbar className={classes.toolbar}>
        <ToolbarGroup role="presentation">
          {isManager && [
            <Tooltip
              key="edit"
              content={t("games.detailToolbar.edit")}
              relationship="label"
            >
              <ToolbarButton
                aria-label={t("games.detailToolbar.edit")}
                appearance="subtle"
                onClick={onEdit}
                icon={<EditRegular />}
              />
            </Tooltip>,

            <Tooltip
              key="delete"
              content={t("games.detailToolbar.delete")}
              relationship="label"
            >
              <ToolbarButton
                aria-label={t("games.detailToolbar.delete")}
                appearance="subtle"
                onClick={onDelete}
                icon={
                  <DeleteRegular color={tokens.colorPaletteRedForeground1} />
                }
              />
            </Tooltip>,
          ]}
        </ToolbarGroup>
        <ToolbarGroup role="presentation">
          {isPlayer ? (
            <Tooltip
              content={t("games.detailToolbar.leave")}
              relationship="label"
            >
              <ToolbarButton
                onClick={onLeave}
                aria-label={t("games.detailToolbar.leave")}
                appearance="subtle"
                disabled={pendingLeaveRequest}
                icon={
                  pendingLeaveRequest ? (
                    <Spinner size="tiny" />
                  ) : (
                    <PersonProhibitedRegular />
                  )
                }
              />
            </Tooltip>
          ) : (
            <Tooltip
              content={t("games.detailToolbar.join")}
              relationship="label"
            >
              <ToolbarButton
                onClick={onJoin}
                aria-label={t("games.detailToolbar.join")}
                appearance="primary"
                icon={<PersonAddRegular />}
              />
            </Tooltip>
          )}
        </ToolbarGroup>
      </Toolbar>
    </div>
  );
};

import { SelectTabData, SelectTabEvent } from "@fluentui/react-components";
import { Person16Filled, Sport16Filled } from "@fluentui/react-icons";
import { SideNav, SideNavItem } from "@layout";
import { Outlet, useNavigate } from "react-router-dom";

export const Games = () => {
  const navigate = useNavigate();
  const onTabChange = (event: SelectTabEvent, { value }: SelectTabData) => {
    navigate(`/games${value ? `/${value}` : ""}`);
  };
  const items: SideNavItem[] = [
    {
      labelKey: "games.games.available",
      value: "",
      icon: <Sport16Filled />,
    },
    {
      labelKey: "games.games.my",
      value: "my",
      icon: <Person16Filled />,
    },
    // {
    //   labelKey: "games.games.history",
    //   value: "history",
    //   icon: <History16Filled />,
    // },
  ];
  return (
    <>
      <SideNav
        tabChange={onTabChange}
        items={items}
        defaultSelectedValue={items[0]?.value}
      />
      <section className="flex flex-col grow">
        <Outlet />
      </section>
    </>
  );
};

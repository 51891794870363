import { OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";
import {
  Button,
  Combobox,
  Field,
  Input,
  Option,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Spinner,
  Subtitle1,
  Switch,
  Textarea,
  Toast,
  ToastBody,
  ToastTitle,
  Tooltip,
  useToastController,
} from "@fluentui/react-components";
import { CalendarMonthRegular } from "@fluentui/react-icons";
import { api } from "@services";
import { CountedResults, Discipline, Game, GameDetails, Place } from "@types";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";

type FormData = {
  disciplineId?: number;
  disciplineName: string;
  title: string;
  date: Date;
  cost: number;
  description: string;
  durationHours: number;
  durationMinutes: number;
  timeHours: number;
  timeMinutes: number;
  minPlayers: number;
  maxPlayers: number;
  placeName: string;
  placeId?: number;
  priorityCode: string;
  comment?: string;
  private: boolean;
  outdoor: boolean;
  join: boolean;
};

export const AddEditGame = () => {
  const { t, i18n } = useTranslation();
  const [disciplines, setDisciplines] = useState<Discipline[]>([]);
  const [places, setPlaces] = useState<Place[]>([]);
  const [matchingDisciplines, setMatchingDisciplines] = useState<Discipline[]>(
    []
  );
  const [title, setTitle] = useState("");
  const [selectedDiscipline, setSelectedDiscipline] = useState<string[]>([]);
  const [disciplineValue, setDisciplineValue] = useState<string>("");

  const [selectedPlace, setSelectedPlace] = useState<string[]>([]);
  const [placeValue, setPlaceValue] = useState<string>("");
  const [matchingPlaces, setMatchingPlaces] = useState<Place[]>([]);

  const [timeHours, setTimeHours] = useState(moment(new Date()).hours());
  const [timeMinutes, setTimeMinutes] = useState(moment(new Date()).minutes());

  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState("");

  const [privateGame, setPrivateGame] = useState(false);
  const [outdoor, setOutdoor] = useState(false);

  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);

  const [priorityCode, setPriorityCode] = useState("");

  const [game, setGame] = useState<Game | undefined>(undefined);
  const loaderData = useLoaderData() as {
    disciplines: CountedResults<Discipline>;
    places: CountedResults<Place>;
    game: Game;
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    setDisciplines(loaderData.disciplines.rows);
    setPlaces(loaderData.places.rows);
    setGame(loaderData.game);
    setMatchingDisciplines(loaderData.disciplines.rows);
    setMatchingPlaces(loaderData.places.rows);

    if (game) {
      setTitle(game.title);
      setValue("title", game.title);

      setDisciplineValue(t(`disciplines.${game.discipline.name}`));
      setSelectedDiscipline([`${game.disciplineId}`]);
      setValue("disciplineId", game.disciplineId);
      setValue("disciplineName", t(`disciplines.${game.discipline.name}`));

      setSelectedPlace([`${game.placeId}`]);
      setPlaceValue(displayPlaceName(game.place));
      setValue("placeId", game.placeId);
      setValue("placeName", displayPlaceName(game.place));

      setCalendarValue(moment(game.date).format("yyyy-MM-DD"));
      setCalendarPopoverValue(game.date);
      setValue("date", moment(game.date).toDate());

      setTimeHours(moment(game.date).hours());
      setTimeMinutes(moment(game.date).minutes());
      setValue("timeHours", moment(game.date).hours());
      setValue("timeMinutes", moment(game.date).minutes());

      setMinPlayers(game.minPlayers);
      setMaxPlayers(game.maxPlayers);
      setValue("minPlayers", game.minPlayers);
      setValue("maxPlayers", game.maxPlayers);

      setDescription(game.description ?? "");
      setValue("description", game.description ?? "");

      setCost(game.cost ?? 0);
      setValue("cost", game.cost ?? 0);

      setDurationHours(+game.duration.split(":")[0]);
      setDurationMinutes(+game.duration.split(":")[1]);
      setValue("durationHours", +game.duration.split(":")[0]);
      setValue("durationMinutes", +game.duration.split(":")[1]);

      setPrivateGame(game.private);
      setValue("private", game.private);

      setOutdoor(game.outdoor);
      setValue("outdoor", game.outdoor);
    }
  }, [loaderData, game, setValue, t]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pendingRequest, setPendingRequest] = useState(false);

  const navigate = useNavigate();

  const { dispatchToast } = useToastController("mainToaster");

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [calendarValue, setCalendarValue] = useState<string>(
    moment().format("yyyy-MM-DD")
  );
  const [calendarPopoverValue, setCalendarPopoverValue] = useState<Date>(
    moment().toDate()
  );

  const join = watch("join", false);
  const [minPlayers, setMinPlayers] = useState(0);
  const [maxPlayers, setMaxPlayers] = useState(0);

  const update = async ({
    disciplineId,
    placeId,
    description,
    maxPlayers,
    minPlayers,
    date,
    timeHours,
    timeMinutes,
    durationHours,
    durationMinutes,
    private: isPrivate,
    outdoor,
    cost,
    priorityCode,
    title,
  }: FormData) => {
    setPendingRequest(true);

    const dto: Partial<GameDetails> = {
      description,
      maxPlayers,
      minPlayers,
      date: moment(date)
        .hours(timeHours)
        .minutes(timeMinutes)
        .startOf("minutes")
        .toDate(),
      duration: `${durationHours}:${durationMinutes}`,
      private: isPrivate,
      outdoor,
      cost,
      title,
    };

    if (priorityCode) dto.priorityCode = priorityCode;

    const body = {
      dto,
      discipline: disciplineId,
      place: placeId,
    };

    if (!game) return;

    const axiosResponse = await api.patch<Game>(`games/${game.id}`, body);

    const updatedGame = axiosResponse.data;

    setPendingRequest(false);
    navigate(`/games/${updatedGame.id}`);
    dispatchToast(
      <Toast>
        <ToastTitle>
          {t("games.addEditGame.toast.successUpdateTitle")}
        </ToastTitle>
        <ToastBody>
          {t("games.addEditGame.toast.successUpdateBody", {
            title: updatedGame.title,
          })}
        </ToastBody>
      </Toast>,
      { intent: "success" }
    );
  };

  const create = async ({
    disciplineId,
    placeId,
    description,
    maxPlayers,
    minPlayers,
    date,
    timeHours,
    timeMinutes,
    durationHours,
    durationMinutes,
    private: isPrivate,
    outdoor,
    join,
    cost,
    priorityCode,
    comment,
    title,
  }: FormData) => {
    setPendingRequest(true);

    const game = {
      disciplineId,
      placeId,
      description,
      maxPlayers,
      minPlayers,
      date: moment(date)
        .hours(timeHours)
        .minutes(timeMinutes)
        .startOf("minutes")
        .toDate(),
      duration: `${durationHours}:${durationMinutes}`,
      private: isPrivate,
      outdoor,
      cost,
      priorityCode,
      title,
    };

    const body = {
      game,
      comment,
    };

    const axiosResponse = await api.post<Game>(
      join ? "games?join=true" : "games",
      body
    );

    const newGame = axiosResponse.data;

    setPendingRequest(false);
    navigate(`/games/${newGame.id}`);
    dispatchToast(
      <Toast>
        <ToastTitle>
          {t("games.addEditGame.toast.successCreateTitle")}
        </ToastTitle>
        <ToastBody>
          {t("games.addEditGame.toast.successCreateBody", {
            title: newGame.title,
          })}
        </ToastBody>
      </Toast>,
      { intent: "success" }
    );
  };

  const handleCalendarOpenChange = (e: any) => {
    setCalendarOpen(!calendarOpen);
  };

  const onDisciplineChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisciplineValue(event.target.value);
    const value = event.target.value.trim();
    const matches = disciplines.filter((option) =>
      t(`disciplines.${option.name}`)
        .toLocaleLowerCase(i18n.language)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          value
            .toLocaleLowerCase(i18n.language)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
    );
    setMatchingDisciplines(matches);
  };

  const displayPlaceName = (place: Place): string => {
    return `${place.name}, ${place.street} ${place.number}, ${place.city}`;
  };

  const onPlaceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPlaceValue(event.target.value);
    const value = event.target.value.trim();
    const matches = places.filter((option) =>
      displayPlaceName(option)
        .toLocaleLowerCase(i18n.language)
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          value
            .toLocaleLowerCase(i18n.language)
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        )
    );
    setMatchingPlaces(matches);
  };

  const onDisciplineSelect = (
    event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    if (data) {
      setValue("disciplineId", +data.optionValue!);
      setDisciplineValue(data.optionText ?? "");
      setSelectedDiscipline(data.selectedOptions);
      clearErrors("disciplineName");
    }
  };

  const onPlaceSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
    if (data) {
      setValue("placeId", +data.optionValue!);
      setPlaceValue(data.optionText ?? "");
      setSelectedPlace(data.selectedOptions);
      clearErrors("placeName");
    }
  };

  return (
    <section className="p-4">
      <Subtitle1>{game ? game.title : t("games.addEditGame.title")}</Subtitle1>

      <form onSubmit={handleSubmit(create)}>
        <section className="flex items-start flex-wrap md:flex-nowrap">
          <input type="hidden" {...register("disciplineId")} />
          <Field
            className="flex-grow w-full sm:w-1/2"
            label={t("games.addEditGame.discipline")}
            required
            validationMessage={errors.disciplineName?.message}
          >
            {(fieldProps) => (
              <Combobox
                {...register("disciplineName", {
                  required: t("errors.required"),
                })}
                {...fieldProps}
                value={disciplineValue}
                selectedOptions={selectedDiscipline}
                onInput={onDisciplineChange}
                onOptionSelect={onDisciplineSelect}
              >
                {matchingDisciplines.map((option) => (
                  <Option key={option.id} value={`${option.id}`}>
                    {t(`disciplines.${option.name}`)}
                  </Option>
                ))}
                {matchingDisciplines.length === 0 ? (
                  <Option key="no-results" text="">
                    {t("games.addEditGame.comboBoxNoResult")}
                  </Option>
                ) : null}
              </Combobox>
            )}
          </Field>
          <Field
            className="flex-grow w-full sm:pl-4 sm:w-1/2"
            label={t("games.addEditGame.place")}
            required
            validationMessage={errors.placeName?.message}
          >
            {(fieldProps) => (
              <Combobox
                {...register("placeName", {
                  required: t("errors.required"),
                })}
                {...fieldProps}
                value={placeValue}
                selectedOptions={selectedPlace}
                onInput={onPlaceChange}
                onOptionSelect={onPlaceSelect}
              >
                {matchingPlaces.map((option) => (
                  <Option key={option.id} value={`${option.id}`}>
                    {displayPlaceName(option)}
                  </Option>
                ))}
                {matchingPlaces.length === 0 ? (
                  <Option key="no-results" text="">
                    {t("games.addEditGame.comboBoxNoResult")}
                  </Option>
                ) : null}
              </Combobox>
            )}
          </Field>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Field
            className="flex-grow w-full"
            label={t("games.addEditGame.title")}
            required
            validationMessage={errors.title?.message}
          >
            <Input
              type="text"
              value={title}
              {...register("title", {
                required: t("errors.required"),
              })}
              onChange={(_, data) => setTitle(data.value)}
            />
          </Field>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Field
            className="flex-grow w-full sm:w-3/5"
            label={t("games.addEditGame.date")}
            required
            validationMessage={errors.date?.message}
          >
            <Popover
              open={calendarOpen}
              onOpenChange={handleCalendarOpenChange}
              unstable_disableAutoFocus
            >
              <PopoverTrigger disableButtonEnhancement>
                <Input
                  value={calendarValue}
                  {...register("date", {
                    required: t("errors.required"),
                  })}
                  type="text"
                  contentAfter={
                    <Button
                      onClick={handleCalendarOpenChange}
                      appearance="transparent"
                      icon={<CalendarMonthRegular />}
                    />
                  }
                />
              </PopoverTrigger>

              <PopoverSurface>
                <Calendar
                  defaultValue={calendarPopoverValue}
                  minDate={moment().toDate()}
                  onClickDay={(e) => {
                    setCalendarValue(moment(e).format("yyyy-MM-DD"));
                    setCalendarPopoverValue(moment(e).toDate());
                    setCalendarOpen(false);
                  }}
                />
              </PopoverSurface>
            </Popover>
          </Field>
          <div className="flex items-start w-full sm:pl-4 sm:w-2/5">
            <Tooltip
              content={t("games.addEditGame.timeHoursTooltip")}
              relationship="label"
              positioning="below"
            >
              <Field
                className="w-1/2"
                label={t("games.addEditGame.time")}
                required
                validationMessage={errors.timeHours?.message}
              >
                <Input
                  type="number"
                  min={0}
                  max={23}
                  value={`${timeHours}`}
                  {...register("timeHours", {
                    valueAsNumber: true,
                    required: t("errors.required"),
                    min: { value: 0, message: t("errors.invalid") },
                    max: { value: 23, message: t("errors.invalid") },
                  })}
                  onChange={(_, data) => setTimeHours(+data.value)}
                />
              </Field>
            </Tooltip>
            <Tooltip
              content={t("games.addEditGame.timeMinutesTooltip")}
              relationship="label"
              positioning="below"
            >
              <Field
                className="w-1/2 pl-4"
                label="&nbsp;"
                validationMessage={errors.timeMinutes?.message}
              >
                <Input
                  type="number"
                  min={0}
                  max={59}
                  value={`${timeMinutes}`}
                  {...register("timeMinutes", {
                    validate: (value) => !!value || t("errors.required"),
                    min: { value: 0, message: t("errors.invalid") },
                    max: { value: 59, message: t("errors.invalid") },
                  })}
                  onChange={(_, data) => setTimeMinutes(+data.value)}
                />
              </Field>
            </Tooltip>
          </div>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Field
            label={t("games.addEditGame.minPlayers")}
            className="w-full sm:w-1/2"
            validationMessage={errors.minPlayers?.message}
          >
            <Input
              type="number"
              min={0}
              max={Number.POSITIVE_INFINITY}
              {...register("minPlayers", {
                min: { value: 0, message: t("errors.invalid") },
                valueAsNumber: true,
              })}
              value={`${minPlayers}`}
              onChange={(_, data) => {
                setMinPlayers(+data.value);

                if (maxPlayers && maxPlayers <= minPlayers)
                  setMaxPlayers(+data.value);
              }}
            />
          </Field>
          <Field
            label={t("games.addEditGame.maxPlayers")}
            className="w-full sm:w-1/2 sm:pl-4"
            validationMessage={errors.maxPlayers?.message}
          >
            <Input
              type="number"
              min={minPlayers}
              max={Number.POSITIVE_INFINITY}
              {...register("maxPlayers", {
                min: { value: 0, message: t("errors.invalid") },
                valueAsNumber: true,
              })}
              value={`${maxPlayers}`}
              onChange={(_, data) => {
                setMaxPlayers(+data.value);
              }}
            />
          </Field>
        </section>
        <section className="flex items-start mt-4">
          <Field
            label={t("games.addEditGame.description")}
            className="flex-grow"
          >
            <Textarea
              value={description}
              {...register("description")}
              onChange={(_, data) => setDescription(data.value)}
            />
          </Field>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Field
            label={t("games.addEditGame.cost")}
            className="w-full items-start sm:w-3/5"
            validationMessage={errors.cost?.message}
            required
          >
            <Input
              type="number"
              min={0}
              value={`${cost}`}
              {...register("cost", {
                required: t("errors.required"),
                min: { value: 0, message: t("errors.invalid") },
                valueAsNumber: true,
              })}
              onChange={(_, data) => setCost(+data.value)}
            />
          </Field>

          <div className="flex w-full items-start sm:pl-4 sm:w-2/5">
            <Tooltip
              content={t("games.addEditGame.hours")}
              relationship="label"
              positioning="below"
            >
              <Field
                label={t("games.addEditGame.duration")}
                className="w-1/2"
                required
                validationMessage={errors.durationHours?.message}
              >
                <Input
                  type="number"
                  min={0}
                  max={23}
                  value={`${durationHours}`}
                  {...register("durationHours", {
                    required: t("errors.required"),
                    min: { value: 0, message: t("errors.invalid") },
                    max: { value: 23, message: t("errors.invalid") },
                    valueAsNumber: true,
                  })}
                  onChange={(_, data) => setDurationHours(+data.value)}
                />
              </Field>
            </Tooltip>
            <Tooltip
              content={t("games.addEditGame.minutes")}
              relationship="label"
              positioning="below"
            >
              <Field
                label="&nbsp;"
                required
                className="w-1/2 pl-4"
                validationMessage={errors.durationMinutes?.message}
              >
                <Input
                  type="number"
                  min={0}
                  max={59}
                  value={`${durationMinutes}`}
                  {...register("durationMinutes", {
                    required: t("errors.required"),
                    min: { value: 0, message: t("errors.invalid") },
                    max: { value: 59, message: t("errors.invalid") },
                    valueAsNumber: true,
                  })}
                  onChange={(_, data) => setDurationMinutes(+data.value)}
                />
              </Field>
            </Tooltip>
          </div>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Field
            label={
              game
                ? t("games.addEditGame.newPriorityCode")
                : t("games.addEditGame.priorityCode")
            }
            className="flex-grow w-full"
            required={!game}
            validationMessage={errors.priorityCode?.message}
          >
            <Input
              type="text"
              value={priorityCode}
              {...register("priorityCode", {
                required: !game ? t("errors.required") : false,
              })}
              onChange={(_, data) => setPriorityCode(data.value)}
            />
          </Field>
        </section>
        <section className="flex items-start flex-wrap mt-4">
          <Switch
            checked={privateGame}
            {...register("private")}
            label={t("games.addEditGame.private")}
            onChange={(_, data) => setPrivateGame(data.checked)}
          />
          <Switch
            checked={outdoor}
            {...register("outdoor")}
            label={t("games.addEditGame.outdoor")}
            onChange={(_, data) => setOutdoor(data.checked)}
          />
          {!game && (
            <Switch {...register("join")} label={t("games.addEditGame.join")} />
          )}
        </section>
        {join && !game && (
          <section className="items-start mt-4">
            <Field
              label={t("games.addEditGame.comment")}
              validationMessage={errors.comment?.message}
            >
              <Input type="text" {...register("comment")} />
            </Field>
          </section>
        )}

        <section className="flex gap-4 mt-4">
          <Button
            appearance="primary"
            onClick={game ? handleSubmit(update) : handleSubmit(create)}
            disabled={pendingRequest}
            icon={pendingRequest ? <Spinner size="tiny" /> : null}
          >
            {game
              ? t("games.addEditGame.update")
              : t("games.addEditGame.create")}
          </Button>
          <Button onClick={() => navigate(-1)} appearance="secondary">
            {t("games.addEditGame.cancel")}
          </Button>
        </section>
      </form>
    </section>
  );
};

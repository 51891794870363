import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  Spinner,
} from "@fluentui/react-components";
import { Game } from "@types";
import useAuth from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormData = {
  playername?: string;
  comment: string;
  priorityCode: string;
  securityCode?: string;
};

type JoinDialogProps = {
  open: boolean;
  pendingRequest: boolean;
  game: Game;
  onOpenChange: (open: boolean) => void;
  submitHandler: ({
    playername,
    comment,
    priorityCode,
    securityCode,
  }: FormData) => Promise<void> | void;
};

export const JoinDialog = (props: JoinDialogProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormData>();
  const { isLoggedIn } = useAuth();

  return (
    <Dialog
      open={props.open}
      onOpenChange={(event, data) => props.onOpenChange(data.open)}
    >
      <DialogSurface>
        <form onSubmit={handleSubmit(props.submitHandler)}>
          <DialogBody>
            <DialogTitle>
              {t("games.joinDialog.title", {
                gameTitle: props.game.title,
              })}
            </DialogTitle>
            <DialogContent>
              {!isLoggedIn && (
                <>
                  <Field label={t("games.joinDialog.playername")}>
                    <Input {...register("playername")} />
                  </Field>
                  <Field label={t("games.joinDialog.securityCode")}>
                    <Input {...register("securityCode")} />
                  </Field>
                </>
              )}
              <Field label={t("games.joinDialog.priorityCode")}>
                <Input {...register("priorityCode")} />
              </Field>
              <Field label={t("games.joinDialog.comment")}>
                <Input {...register("comment")} />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={props.pendingRequest}
                type="submit"
                appearance="primary"
                icon={props.pendingRequest ? <Spinner size="tiny" /> : null}
              >
                {t("games.joinDialog.join")}
              </Button>
              <DialogTrigger disableButtonEnhancement>
                <Button disabled={props.pendingRequest} appearance="secondary">
                  {t("games.joinDialog.cancel")}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

import {
  Avatar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Tooltip,
  createTableColumn,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowOutlineUpRightRegular,
  CircleRegular,
  CommentRegular,
  MoneyHandRegular,
  MoneyOffRegular,
  MoneyRegular,
  NumberSymbolRegular,
  PersonDeleteRegular,
  WarningRegular,
} from "@fluentui/react-icons";
import { Game } from "@types";
import { t } from "i18next";
import moment from "moment";

type PlayersTableProps = {
  game: Game;
  isManager: boolean;
  paidChange: (ids: number[], paid: boolean) => void;
  removePlayer: (id: number) => void;
};

type PlayerRow = {
  id: number;
  playername: string | undefined;
  priority: boolean;
  comment: string;
  joined: Date;
  paid: boolean;
  index: number;
  guest: boolean;
};

const useStyles = makeStyles({
  table: {
    "& th:first-child": {
      width: "100px",
    },
    "& th:last-child": {
      width: "60px",
    },
  },
});

export const PlayersTable = ({
  game,
  isManager,
  paidChange,
  removePlayer,
}: PlayersTableProps) => {
  const styles = useStyles();

  const players: PlayerRow[] | undefined = game.players.map(
    (player, index) => ({
      id: player.id,
      playername: player.user?.username || player.playername,
      priority: player.GamePlayer.priority,
      comment: player.GamePlayer.comment,
      joined: player.GamePlayer.joinedAt,
      paid: player.GamePlayer.paid,
      index,
      guest: !player.userId,
    })
  );
  const columns: TableColumnDefinition<PlayerRow>[] = [
    createTableColumn<PlayerRow>({
      columnId: "index",
      compare: (a, b) => {
        return a.index - b.index;
      },
      renderHeaderCell: () => {
        return "#";
      },
      renderCell: (item) => {
        return (
          <Tooltip
            relationship="label"
            content={t("games.details.joined", {
              date: moment(item.joined).format("yyyy-MM-DD HH:mm:ss"),
            })}
          >
            <TableCellLayout
              truncate
              media={<NumberSymbolRegular fontSize={16} />}
            >
              {item.index + 1}
            </TableCellLayout>
          </Tooltip>
        );
      },
    }),
    createTableColumn<PlayerRow>({
      columnId: "playername",
      compare: (a, b) => {
        return a.playername!.localeCompare(b.playername!);
      },
      renderHeaderCell: () => {
        return t("games.details.player");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout
            appearance="primary"
            truncate
            media={
              <Avatar
                aria-label={item.playername}
                name={item.playername}
                badge={{
                  icon: item.priority ? (
                    <ArrowOutlineUpRightRegular fontSize={16} />
                  ) : item.index >= game.maxPlayers ? (
                    <WarningRegular
                      fontSize={16}
                      color={tokens.colorPaletteYellowForeground1}
                    />
                  ) : (
                    <CircleRegular
                      fontSize={16}
                      color={tokens.colorNeutralForeground1Static}
                    />
                  ),
                }}
              />
            }
          >
            {item.comment ? (
              <Tooltip relationship="label" content={item.comment}>
                <span>
                  <CommentRegular fontSize={16} /> {item.comment}
                </span>
              </Tooltip>
            ) : (
              item.playername
            )}
            <TableCellActions>
              <Tooltip
                relationship="label"
                content={t("games.playersTable.removePlayer")}
              >
                <Button
                  icon={
                    <PersonDeleteRegular
                      color={tokens.colorPaletteRedForeground1}
                      fontSize={16}
                    />
                  }
                  appearance="subtle"
                  onClick={() => removePlayer(item.id)}
                  aria-label={t("games.playersTable.removePlayer")}
                />
              </Tooltip>
            </TableCellActions>
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<PlayerRow>({
      columnId: "paid",
      renderHeaderCell: () => {
        return <MoneyRegular fontSize={16} />;
      },
      renderCell: (item) => {
        return (
          <>
            <TableCellLayout
              media={
                isManager ? (
                  <Tooltip
                    relationship="label"
                    content={
                      item.paid
                        ? t("games.playersTable.markAsNotPaid")
                        : t("games.playersTable.markAsPaid")
                    }
                  >
                    <Button
                      icon={
                        item.paid ? (
                          <MoneyHandRegular
                            fontSize={16}
                            color={tokens.colorPaletteGreenForeground1}
                          />
                        ) : (
                          <MoneyOffRegular
                            fontSize={16}
                            color={tokens.colorPaletteRedForeground1}
                          />
                        )
                      }
                      appearance="subtle"
                      onClick={() => paidChange([item.id], !item.paid)}
                      aria-label={
                        item.paid
                          ? t("games.playersTable.markAsNotPaid")
                          : t("games.playersTable.markAsPaid")
                      }
                    />
                  </Tooltip>
                ) : item.paid ? (
                  <MoneyHandRegular
                    fontSize={16}
                    color={tokens.colorPaletteGreenForeground1}
                  />
                ) : (
                  <MoneyOffRegular
                    fontSize={16}
                    color={tokens.colorPaletteRedForeground1}
                  />
                )
              }
            />
          </>
        );
      },
    }),
  ];

  return (
    <Table className={styles.table}>
      <TableHeader>
        <TableRow>
          {columns.map((column) => (
            <TableHeaderCell key={column.columnId}>
              {column.renderHeaderCell()}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {players.map((player) => (
          <TableRow key={player.id}>
            {columns.map((column) => (
              <TableCell key={column.columnId}>
                {column.renderCell(player)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
